import Booth from '../../components/boothpage/Booth';

const BoothPage = () => {
  return (
    <>
      <Booth />
    </>
  );
};

export default BoothPage;
