import EditMenuDetail from '../../components/editmenupage/EditMenuDetail';

const EditMenuDetailPage = () => {
  return (
    <>
      <EditMenuDetail />
    </>
  );
};

export default EditMenuDetailPage;
