import EditMenu from '../../components/editmenupage/EditMenu';

const EditMenuPage = () => {
  return (
    <>
      <EditMenu />
    </>
  );
};

export default EditMenuPage;
