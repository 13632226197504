import TrashBin from '../components/trashbinpage/TrashBin';

const TrashBinPage = () => {
  return (
    <>
      <TrashBin />
    </>
  );
};

export default TrashBinPage;
