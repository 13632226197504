import Main from '../components/mainpage/Main';

const MainPage = () => {
  return (
    <>
      <Main />
    </>
  );
};

export default MainPage;
