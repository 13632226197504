import BoothDetail from '../../components/boothdetailpage/BoothDetail';

const BoothDetailPage = () => {
  return (
    <>
      <BoothDetail />
    </>
  );
};

export default BoothDetailPage;
