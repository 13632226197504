export const dayData = [
  { date: 10, day: '수요일' },
  { date: 11, day: '목요일' },
  { date: 12, day: '금요일' },
];
export const locationData = [
  '정문',
  '교육관',
  '대강당',
  '휴웃길',
  '포스코관',
  '학문관',
  '생활관',
  '신세계관',
];

export const categoryData = ['음식', '굿즈', '체험', '기타'];
