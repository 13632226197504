import React from 'react';
import Search from '../components/searchpage/Search';

const SearchPage = () => {
  return (
    <>
      <Search />
    </>
  );
};

export default SearchPage;
