import MyMenu from '../../components/mypage/MyMenu';
const MyPage = () => {
  return (
    <>
      <MyMenu />
    </>
  );
};

export default MyPage;
