export const event1 = [
  { id: 1, title: '이화인 한솥밥 배부', time: '12:00' },
  { id: 2, title: '영산 줄다리기', time: '15:30' },
];

export const event2 = [{ id: 1, title: "EWHA'S TURNTABLE", time: '19:30' }];

export const event3 = [
  { id: 1, title: '이화의 목소리가 보여', time: '17:30 - 18:30' },
  { id: 2, title: '이화그린 영화제', time: '19:00-21:00' },
];
