export const noticesdata = [
  {
    id: 1,
    user: 101, // TF 유저 id 101
    title: '대통령은 헌법과 법률이 정하는 바에 의하여 국군을 통수한다.',
    content:
      '공무원은 국민전체에 대한 봉사자이며, 국민에 대하여 책임을 진다. 군인·군무원·경찰공무원 기타 법률이 정하는 자가 전투·훈련등 직무집행과 관련하여 받은 손해에 대하여는 법률이 정하는 보상외에 국가 또는 공공단체에 공무원의 직무상 불법행위로 인한 배상은 청구할 수 없다. 근로조건의 기준은 인간의 존엄성을 보장하도록 법률로 정한다.\n\n제1항의 해임건의는 국회재적의원 3분의 1 이상의 발의에 의하여 국회재적의원 과반수의 찬성이 있어야 한다. 모든 국민은 인간다운 생활을 할 권리를 가진다. 대통령은 헌법과 법률이 정하는 바에 의하여 국군을 통수한다. 국가는 모성의 보호를 위하여 노력하여야 한다. 선거에 관한 경비는 법률이 정하는 경우를 제외하고는 정당 또는 후보자에게 부담시킬 수 없다.\n\n모든 국민의 재산권은 보장된다. 그 내용과 한계는 법률로 정한다. 국회는 의장 1인과 부의장 2인을 선출한다. 행정각부의 설치·조직과 직무범위는 법률로 정한다. 법률이 헌법에 위반되는 여부가 재판의 전제가 된 경우에는 법원은 헌법재판소에 제청하여 그 심판에 의하여 재판한다. 헌법재판소는 법관의 자격을 가진 9인의 재판관으로 구성하며, 재판관은 대통령이 임명한다.',
    created_at: '2023-05-12 10:59',
    updated_at: '2023-05-12 11:02',
  },
  {
    id: 2,
    user: 101, // TF 유저 id 101
    title: '군인은 현역을 면한 후가 아니면 국무총리로 임명될 수 없다.',
    content:
      '위원은 정당에 가입하거나 정치에 관여할 수 없다. 재의의 요구가 있을 때에는 국회는 재의에 붙이고, 재적의원과반수의 출석과 출석의원 3분의 2 이상의 찬성으로 전과 같은 의결을 하면 그 법률안은 법률로서 확정된다. 대통령은 국무회의의 의장이 되고, 국무총리는 부의장이 된다. 학교교육 및 평생교육을 포함한 교육제도와 그 운영, 교육재정 및 교원의 지위에 관한 기본적인 사항은 법률로 정한다. 국무총리는 국무위원의 해임을 대통령에게 건의할 수 있다. 국가는 농업 및 어업을 보호·육성하기 위하여 농·어촌종합개발과 그 지원등 필요한 계획을 수립·시행하여야 한다. 정부는 회계연도마다 예산안을 편성하여 회계연도 개시 90일전까지 국회에 제출하고, 국회는 회계연도 개시 30일전까지 이를 의결하여야 한다.\n\n국무위원은 국무총리의 제청으로 대통령이 임명한다. 국회는 법률에 저촉되지 아니하는 범위안에서 의사와 내부규율에 관한 규칙을 제정할 수 있다. 국가안전보장회의의 조직·직무범위 기타 필요한 사항은 법률로 정한다. 국회는 의장 1인과 부의장 2인을 선출한다. 국회에서 의결된 법률안은 정부에 이송되어 15일 이내에 대통령이 공포한다. 공무원인 근로자는 법률이 정하는 자에 한하여 단결권·단체교섭권 및 단체행동권을 가진다. 비상계엄하의 군사재판은 군인·군무원의 범죄나 군사에 관한 간첩죄의 경우와 초병·초소·유독음식물공급·포로에 관한 죄중 법률이 정한 경우에 한하여 단심으로 할 수 있다. 다만, 사형을 선고한 경우에는 그러하지 아니하다.',
    created_at: '2023-05-11 09:16',
    updated_at: '2023-05-11 12:37',
  },
  {
    id: 3,
    user: 101, // TF 유저 id 101
    title:
      '국회는 국민의 보통·평등·직접·비밀선거에 의하여 선출된 국회의원으로 구성한다.',
    content:
      '공무원은 국민전체에 대한 봉사자이며, 국민에 대하여 책임을 진다. 군인·군무원·경찰공무원 기타 법률이 정하는 자가 전투·훈련등 직무집행과 관련하여 받은 손해에 대하여는 법률이 정하는 보상외에 국가 또는 공공단체에 공무원의 직무상 불법행위로 인한 배상은 청구할 수 없다. 근로조건의 기준은 인간의 존엄성을 보장하도록 법률로 정한다.\n\n제1항의 해임건의는 국회재적의원 3분의 1 이상의 발의에 의하여 국회재적의원 과반수의 찬성이 있어야 한다. 모든 국민은 인간다운 생활을 할 권리를 가진다. 대통령은 헌법과 법률이 정하는 바에 의하여 국군을 통수한다. 국가는 모성의 보호를 위하여 노력하여야 한다. 선거에 관한 경비는 법률이 정하는 경우를 제외하고는 정당 또는 후보자에게 부담시킬 수 없다.\n\n모든 국민의 재산권은 보장된다. 그 내용과 한계는 법률로 정한다. 국회는 의장 1인과 부의장 2인을 선출한다. 행정각부의 설치·조직과 직무범위는 법률로 정한다. 법률이 헌법에 위반되는 여부가 재판의 전제가 된 경우에는 법원은 헌법재판소에 제청하여 그 심판에 의하여 재판한다. 헌법재판소는 법관의 자격을 가진 9인의 재판관으로 구성하며, 재판관은 대통령이 임명한다.',
    created_at: '2023-05-10 20:41',
    updated_at: '2023-05-10 20:41',
  },
  {
    id: 4,
    user: 101, // TF 유저 id 101
    title: '대한민국은 국제평화의 유지에 노력하고 침략적 전쟁을 부인한다.',
    content:
      '위원은 정당에 가입하거나 정치에 관여할 수 없다. 재의의 요구가 있을 때에는 국회는 재의에 붙이고, 재적의원과반수의 출석과 출석의원 3분의 2 이상의 찬성으로 전과 같은 의결을 하면 그 법률안은 법률로서 확정된다. 대통령은 국무회의의 의장이 되고, 국무총리는 부의장이 된다. 학교교육 및 평생교육을 포함한 교육제도와 그 운영, 교육재정 및 교원의 지위에 관한 기본적인 사항은 법률로 정한다. 국무총리는 국무위원의 해임을 대통령에게 건의할 수 있다. 국가는 농업 및 어업을 보호·육성하기 위하여 농·어촌종합개발과 그 지원등 필요한 계획을 수립·시행하여야 한다. 정부는 회계연도마다 예산안을 편성하여 회계연도 개시 90일전까지 국회에 제출하고, 국회는 회계연도 개시 30일전까지 이를 의결하여야 한다.\n\n국무위원은 국무총리의 제청으로 대통령이 임명한다. 국회는 법률에 저촉되지 아니하는 범위안에서 의사와 내부규율에 관한 규칙을 제정할 수 있다. 국가안전보장회의의 조직·직무범위 기타 필요한 사항은 법률로 정한다. 국회는 의장 1인과 부의장 2인을 선출한다. 국회에서 의결된 법률안은 정부에 이송되어 15일 이내에 대통령이 공포한다. 공무원인 근로자는 법률이 정하는 자에 한하여 단결권·단체교섭권 및 단체행동권을 가진다. 비상계엄하의 군사재판은 군인·군무원의 범죄나 군사에 관한 간첩죄의 경우와 초병·초소·유독음식물공급·포로에 관한 죄중 법률이 정한 경우에 한하여 단심으로 할 수 있다. 다만, 사형을 선고한 경우에는 그러하지 아니하다.',
    created_at: '2023-05-10 17:53',
    updated_at: '2023-05-10 17:55',
  },
];
