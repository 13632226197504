import EditBooth from '../../components/editboothpage/EditBooth';

const EditBoothPage = () => {
  return (
    <>
      <EditBooth />
    </>
  );
};

export default EditBoothPage;
