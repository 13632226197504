import RegisterMenu from '../../components/authpage/RegisterMenu';
const RegisterPage = () => {
  return (
    <>
      <RegisterMenu />
    </>
  );
};

export default RegisterPage;
