import LoginMenu from '../../components/authpage/LoginMenu';
const LoginPage = () => {
  return (
    <>
      <LoginMenu />
    </>
  );
};

export default LoginPage;
